<template>
    <content-title link_field="生活"></content-title>

    <article-list  nav_title="生活" :article_class="16"></article-list>
</template>

<script>
import ContentTitle from "../components/public/ContentTitle";
import ArticleList from "../components/article/ArticleList";
import ArticleNav from "../components/public/ArticleNav";
export default {
    data(){
        return {

        }
    },
    components: {
        ContentTitle,
        ArticleList,
        ArticleNav,
    },
}
</script>
