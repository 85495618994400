<template>
    <div class="article-list-box">
        <div class="main-content-box">
            <div class="article-list-box-two">
                <div class="article-list">
                    <div class="article-nav-select">
                        <el-select v-model="red_class" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.type_id"
                                :label="item.type_name"
                                :value="item.type_id">
                            </el-option>
                        </el-select>
                    </div>

<!--                        <ul>-->
<!--                            <li v-for="item in article_list">-->
<!--                                <div class="article-img-box">-->
<!--                                    <a href=""><img :src="item.img_src" alt=""></a>-->
<!--                                </div>-->

<!--                                <div class="article-info-box">-->
<!--                                    <div class="article-info-title"><a href="">{{ item.title }}</a></div>-->
<!--                                    <div class="article-info-time"><p>发布日期：{{ item.public_time }}</p></div>-->
<!--                                    <div class="article-info-content"><p>{{ item.list_content }}</p></div>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                        </ul>-->





                    <div class="infinite-list-wrapper" style="overflow-y:hidden">
                        <ul class="list"
                            v-infinite-scroll="load"
                            infinite-scroll-disabled="disabled"
                            infinite-scroll-distance="1000px">
                            <li v-for="(item, index) in article_list" class="list-item" :key="item.article_id">
                                <div class="article-img-box">
                                    <a :href="'/article/' + item.article_id" target="_blank">
                                        <img :src="`${$imgUrl}${item.img_src}`" >
                                    </a>
                                </div>

                                <div class="article-info-box">
                                    <div class="article-info-title"><a :href="'/article/' + item.article_id" target="_blank">{{ item.title }}</a></div>
                                    <div class="article-info-time"><p>发布日期：{{ item.article_time }}</p></div>
                                    <div class="article-info-content text-overflow-two" v-html="item.content"></div>
                                </div>
                            </li>
                        </ul>
                        <div v-if="noMore" style="font-size: 20px;color: #C4C4C4;text-align: center;padding: 20px 0;"><span style="margin: auto">没有更多了</span></div>
                        <div v-if="loading" style="font-size: 20px;color: #C4C4C4;text-align: center;padding: 20px 0;"><span style="margin: auto">加载中...</span></div>
                    </div>
                </div>

                <article-nav :article_class="article_class">
                    <slot>
                        <div class="nav-link-list">
                            <ul>
                                <li v-for="item in options" :key="item.type_id" @click="red_class=item.type_id" :class="{ 'red-font': red_class===item.type_id }">
                                    <span><i class="el-icon-caret-right"></i>&emsp;{{ item.type_name }}</span>
                                </li>
                            </ul>
                        </div>
                    </slot>
                </article-nav>
            </div>
        </div>
    </div>

</template>

<script>
import ArticleNav from "../public/ArticleNav";
import {ElMessage} from "element-plus";
// import {WOW} from 'wowjs'
export default {
    name: "ArticleList",
    props: ['nav_title', 'article_class', ],
    components: {
        ArticleNav,
    },

    data() {
        return {
            red_class: '',
            loading: true,
            article_list: [],
            options: [],
            page: 1,
            noMore: false, // 显示《没有更多的数据》
            linshiList: [],
        }


    },
    // mounted(){
    //     var wow = new WOW({
    //         boxClass: 'wow',
    //         animateClass: 'animated',
    //         offset: 0,
    //         mobile: false,
    //         live: true
    //     });
    //     wow.init();
    // },
    created() {
        var that = this;
        that.axios.get('/article_type?article_class=' + that.article_class).
        then(function (response) {
            that.options = response.data.data_list;
            that.red_class = that.options[0].type_id;
            // that.getDataList(that.red_class, that.page);
        }).catch(function (error) {
            ElMessage({
                showClose: true,
                message: '文章分类获取失败，请刷新',
                type: 'error'
            })
        })


    },

    computed: {
        disabled () {
            return this.loading || this.noMore
        },

    },
    methods: {

        load () {
            this.page += 1;
        },

        getDataList: function (type_id, page = 1) {
            var that = this;
            that.axios.get('/article_list?article_type=' + type_id + '&page=' + page).
            then(function (response) {

                that.linshiList = [];
                response.data.data_list.forEach((item, i) => {
                    that.article_list.push(item);
                })

                if (response.data.data_list.length < 6) {
                    that.noMore = true
                }else{
                    that.noMore = false;
                }
                that.loading = false;

            }).catch(function (error) {
                console.log(error)
                ElMessage({
                    showClose: true,
                    message: '文章列表获取失败，请刷新',
                    type: 'error'
                })
            })
        },


    },


    watch: {
        red_class: function () {
            this.article_list = [];
            if (this.page === 1) {
                this.loading = true
                this.getDataList(this.red_class, this.page);
                // this.loading = false;
                // this.noMore = false;
            }
            this.page = 1;
        },
        page: function () {
            this.loading = true
            this.getDataList(this.red_class, this.page);
        },

    },


}
</script>

<!--外层的盒子-->
<style>
    .article-list-box {
        width: 100%;
        background-color: #ffffff;
        color: #666666;;
    }

    .article-list-box-two{
        max-width: 1250px;
        width: 100%;
        margin: 0 auto;
        padding: 35px 0;
        display: flex;
    }
    .article-list{
        /*max-width: 100%;*/
        flex-basis: 75%;
        flex-grow: 1;
        /*overflow:scroll;*/
    }

</style>

<!--文章列表-->
<style>
    .article-list li{
        margin-bottom: 20px;
        padding-right: 10px;
        display: table;
    }
    .article-list li:hover .article-info-title a{
        color: red;
    }
    .article-img-box{
        width:355px;
        /*height: 100%;*/
        padding-right: 30px;
        vertical-align: top;
    }
    .article-img-box img{
        width: 100%;
        /*height: 100%;*/
    }
    .article-img-box a{
        display: block;
    }

    .article-info-box{
        display: table-cell;
        vertical-align: top;
    }

    .article-info-time, .article-info-content{
        padding-top: 20px;
    }


    .article-info-title a{
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        display: inline-block;
    }
    .article-info-time{
        color: #999999;
        font-size: 12px;
    }
    .article-info-content{
        color: #666666;
        font-size: 14px;
        max-height: 55px;
        -webkit-line-clamp: 3;
    }

    .article-info-title{
        padding-top: 50px;
    }


    @media screen and (min-width: 990px) and (max-width: 9999px) {
        .article-info-box{
            padding: 0 50px 0 25px;
        }

        .article-nav-select{
            display: none;
        }

        .list li{
            min-height: 250px!important;
        }

    }


    @media screen and (min-width: 500px) and (max-width: 990px) {
        .article-img-box{
            display: none!important;
        }
        .article-list-box-two{
            justify-content: center;
        }
        .article-list{
            flex-grow: 0.6;
        }
    }
    @media screen and (min-width: 0px) and (max-width: 500px) {
        .article-img-box{
            width: 100%;
            padding: 0;
        }
        .article-list li{
            display: block;
            padding: 0;
        }
        .article-info-title{
            padding-top: 20px;
        }

        .article-list li{
            padding-top: 25px;
        }
    }

</style>

<!--element-ui的选择框-->
<style scoped>
    .article-nav-select{
        width: 100%;
    }
    /*.el-popper{*/
    /*    top: 37px!important;*/
    /*}*/
</style>

<!--element-ui的分页-->
<style>
.el-pagination li{
    display: inline-block!important;
}
</style>
